import React from "react";
import Layout from "../components/layout/layout";
import Seo from "../components/layout/seo";
import styled from "styled-components";

const Despre = () => {
  return (
    <Layout>
      <Seo title="Despre noi" />
      <Stele></Stele>
      <Wrapper>
        <Title>Politica de confidențialitate</Title>
        <Title2>
          NOTĂ DE INFORMARE
          <br />
          PRIVIND PRELUCRAREA DATELOR CU CARACTER PERSONAL
        </Title2>
        <Subtitle>
          <Strong>A. REZERVARE PRIN TELEFON SAU E-MAIL</Strong> <br />
          <br />
          <Strong>NOTIFICARE DE CONFIDENȚIALITATE</Strong> <br />
          În cele ce urmează puteți regăsi informațiile care vă explică modul în
          care colectăm datele cu caracter personal în procesul de rezervare a
          unei mese sau a întregului restaurant pentru evenimente prin telefon
          sau prin e-mail. <br />
          <Strong>CE INFORMAȚII COLECTĂM DESPRE DUMNEAVOASTRĂ? </Strong> <br />
          Pentru a face o rezervare prin telefon sau prin e-mail avem nevoie de
          următoarele date cu caracter personal: nume și prenume; numărul de
          telefon; adresa dumneavoastră de e-mail (în cazul evenimentelor).
          Numărul de telefon și adresa de e-mail ne sunt necesare pentru a vă
          putea oferi informații asupra cererii dumneavoastră de rezervare.
          Având în vedere sistemul CCTV instalat în locația noastră, în vederea
          asigurării protecței bunurilor și a persoanelor, vom colecta și date
          biometrice despre dumneavoastră, respectiv imaginea.
          <br />
          <Strong>
            CUM FOLOSIM INFORMAȚIILE DESPRE DUMNEAVOASTRĂ? ?{" "}
          </Strong>{" "}
          <br />
          Toate informații de identificare (nume, prenume, telefon, adresa de
          email) ne sunt necesare pentru a vă garanta rezervarea în restaurantul
          nostru. <br />
          Dacă, din diverse motive, vom fi nevoiți să vă contactăm înainte de
          data evenimentului, vom face acest lucru pe adresa de e-mail sau
          numărul de telefon pe care ni le-ați comunicat. <br />
          Dacă, din diverse motive, vom fi nevoiți să vă contactăm înainte de
          ora rezervării, vom face acest lucru prin numărul de telefon oferit.{" "}
          <br />
          Datele dvs. sunt păstrate doar pentru ziua rezervării și sunt șterse
          imediat după. <br />
          Nu transmitem datele dumneavoastră către alte entități/persoane.
          Datele dumneavoastră cu Caracter Personal pot fi dezvăluite către
          autorități sau instituții publice, în cazul în care există o cerință
          legală în acest sens. <br />
          <Strong>MARKETING </Strong> <br />
          Nu vă vom trimite, în scopuri de marketing, niciun fel de informații
          referitoare la locația noastră sau despre evenimentele pe care le vom
          organiza, decât dacă ne oferiți acordul dumneavoastră. <br />
          <Strong>
            ACCESUL LA INFORMAȚII, RECTIFICARE A DATELOR ȘI PLÂNGERI
          </Strong>{" "}
          <br />
          Vrem să vă asigurăm că toate drepturile dumneavoastră sunt respectate
          și că datele dumneavoastră cu caracter personal sunt integral
          protejate. <br />
          Aveți dreptul să cereți o copie a informațiilor pe care le deținem
          despre dumneavoastră, să cereți rectificarea sau ștergerea datele
          respective, ori să cereți restricționarea prelucrării datelor. <br />
          Dacă doriți să faceți vreuna din operațiunile de mai sus, vă rugăm să
          ne scrieți un e-mail direct de pe adresa de e-mail folosită în
          corespondența cu noi, precum si date necesare si suficiente pentru a
          va identifica corect in baza noastra de date. <br />
          Datele de contact ale departamentului care se ocupă de protecția
          datelor cu caracter personal sunt: bistrogarlic@gmail.com
          <br />
          Aveți dreptul să formulați plângere către Autoritatea Națională de
          Supraveghere a Prelucrării Datelor cu Caracter Personal sau la
          instanțele de judecată, în cazul în care considerați că societatea
          noastra v-a încălcat vreun drept în baza prevederilor legale sau dacă
          considerați că solicitările dumneavoastră nu au fost rezolvate conform
          legii. Mai multe informații găsiți la următoarea adresa:
          http://www.dataprotection.ro/?page=contact
          <br />
          <Strong>VALABILITATEA NOTIFICĂRII DE CONFIDENȚIALITATE</Strong> <br />
          Această variantă a Notificării este în vigoare din Mai 2022 și se va
          actualiza dacă apar noi cerințe legale sau modificări privind
          modalitatea de prelucrare a datelor cu caracter personal,fără o
          notificare prealabilă. Sunteti responsabil să va mențineti informat cu
          privire la orice astfel de modificări care ar putea să va afecteze.{" "}
          <br />
          <br />
          <Strong>B. CCTV CLIENTI</Strong> <br />
          <Strong>NOTIFICARE DE CONFIDENȚIALITATE</Strong> <br />
          În cele ce urmează puteți regăsi informațiile care vă explică modul în
          care putem intra în contact cu unele dintre datele dumneavoastră cu
          caracter personal, în procesul de utilizare a camerelor de
          supraveghere video (CCTV). <br />
          <Strong>CE INFORMAȚII COLECTĂM DESPRE DUMNEAVOASTRĂ? </Strong> <br />
          Sistemul de supraveghere prin camere video pe circuit închis are
          scopul de a asigura securitatea pe proprietatea GARLIC BISTRO și
          integritatea bunurilor societății și siguranța și securitatea
          dumneavoastră, cât și a personalului angajat. Cu toate acestea, în
          procesul de utilizare a camerelor de supraveghere video există
          posibilitatea de a avea acces la imaginea dumneavoastră. <br />
          <Strong>CUM FOLOSIM INFORMAȚIILE DESPRE DUMNEAVOASTRĂ? </Strong>{" "}
          <br />
          Imaginile înregistrate prin sistemul CCTV sunt stocate potrivit
          prevederilor legale timp de cel mult 30 de zile, după care sunt șterse
          în mod automat prin rescriere continuă. <br />
          Prin excepție, în cazuri strict prevăzute de lege și potrivit unor
          proceduri speciale sunt păstrate pentru perioade mai lungi datele care
          surprind aparența săvârșirii unei infracțiuni sau altei fapte
          reprobabile, datele necesare pentru prezentarea mijloacelor de probă
          pentru exercitarea intereselor, drepturilor contractuale și legale și
          apărarea acestora în fața instanțelor/autorităților competente. <br />
          <Strong>
            ACCESUL LA INFORMAȚII, RECTIFICARE A DATELOR ȘI PLÂNGERI
          </Strong>{" "}
          <br />
          Vrem să vă asigurăm că toate drepturile dumneavoastră sunt respectate
          și că datele dumneavoastră cu caracter personal sunt integral
          protejate. Toodata precizam faptul ca toate activitățile de prelucrare
          a datelor dumneavoastră cu caracter personal sunt realizate în strictă
          conformare cu cerințele legale, prin considerarea celor mai ridicate
          standarde de securitate aplicabile în domeniu. <br />
          Aveți dreptul de a accesa, rectifica, șterge, restricționa
          prelucrarea, porta datele dvs. cu caracter personal, de a vă opune la
          prelucrare și de a fi informat cu privire la prelucrarea datelor cu
          caracter personal. <br />
          Accesul la imagini este strict restricționat. Au acces la imagini:{" "}
          <br />
          • Administratorul operatorului și firma care asigură paza (PP PROTECT
          SECURITY S.R.L.) doar cu acordul operatorului și atunci în situații
          excepționale; <br />
          • Reprezentanții instituțiilor publice sau autorități publice, în
          situații excepționale, doar la cererea expresă scrisă și întemeiată a
          respectivelor instituții sau autorități publice. <br />
          Imaginile sunt stocate în sistemul IT de supraveghere video al
          societății. Operațiunile de ștergere sunt automatizate prin rescriere.{" "}
          <br />
          Datele de contact ale departamentului care se ocupă de protecția
          datelor cu caracter personal sunt: bistrogarlic@gmail.com
          <br />
          Aveți dreptul de a formula plângere către Autoritatea Națională de
          Supraveghere a Prelucrării Datelor cu Caracter Personal. sau la
          instanțele de judecată, în cazul în care considerați că societatea
          noastra v-a încălcat vreun drept în baza prevederilor legale sau dacă
          considerați că solicitările dumneavoastră nu au fost rezolvate conform
          legii Mai multe informații găsiți la următoarea adresa:
          http://www.dataprotection.ro/?page=contact
          <br />
          <Strong>VALABILITATEA NOTIFICĂRII DE CONFIDENȚIALITATE</Strong> <br />
          Această variantă a Notificării este în vigoare din Mai 2022 și se va
          actualiza dacă apar noi cerințe legale sau modificări privind
          modalitatea de prelucrare a datelor cu caracter personal ,fără o
          notificare prealabilă. Sunteti responsabil să va mențineti informat cu
          privire la orice astfel de modificări care ar putea să va afecteze.
          <br />
          <br />
          <Strong>C. RECRUTARE</Strong> <br />
          <Strong>NOTIFICARE DE CONFIDENȚIALITATE</Strong> <br />
          În cele ce urmează puteți regăsi informațiile care vă explică modul în
          care colectăm datele cu caracter personal în procesul de recrutare
          pentru posturi vacante în societatea noastră. <br />
          <Strong>CE INFORMAȚII COLECTĂM DESPRE DUMNEAVOASTRĂ? </Strong> <br />
          În procesul de recrutare colectăm informațiile pe care ni le puneți la
          dispoziție prin intermediul documentelor dumneavoastră de prezentare
          (CV, scrisoare de intenție etc.); aceste date personale pot include,
          dar fără a se limita la nume și prenume; data și locul nașterii;
          cetățenia; domiciliul; adresa de email; numărul de telefon; starea
          civilă etc. Aceste informații ne pot parveni, spre exemplu, fie prin
          site-uri specializate de recrutare, fie prin documente trimise prin
          poștă electronică sau email, pe adresele societății noastre, fie
          direct, prin documente prezentate personal. <br />
          <Strong>CUM FOLOSIM INFORMAȚIILE DESPRE DUMNEAVOASTRĂ? </Strong>{" "}
          <br />
          Toate aceste informații ne sunt necesare pentru a examina dacă
          persoana dumneavoastră corespunde cerințelor noastre. Nu transmitem
          datele dumneavoastră cu caracter personal către alte
          entități/persoane. Datele dumneavoastră sunt păstrate doar pe durata
          procesului de recrutare, imediat după finalizarea acestui proces
          datele candidatilor a caror candidatura nu a fost acceptata vor fi
          șterse. În cazul în care sunteți angajat, datele dumneavoastră sunt
          păstrate în contextul relației ulterioare de angajare. <br />
          <Strong>MARKETING</Strong> <br />
          Nu vom folosi datele dumneavoastră cu caracter personal în scopuri de
          marketing. <br />
          <Strong>
            ACCESUL LA INFORMAȚII, RECTIFICARE A DATELOR ȘI PLÂNGERI
          </Strong>{" "}
          <br />
          Datele dumneavoastră cu caracter personal sunt examinate exclusiv de
          personalul care face parte din managementul Bistro Garlic. Vrem să vă
          asigurăm că toate drepturile dumneavoastră sunt respectate și că
          informațiile dumneavoastră cu caracter personal sunt integral
          protejate. Aveți dreptul de a solicita accesul la datele dvs.
          personale, precum și rectificarea sau ștergerea acestora sau
          restricționarea prelucrării, conform legii. În acest scop puteți să ne
          contactați folosind metoda utilizată în corespondență cu noi. <br />
          Datele de contact ale departamentului care se ocupă de protecția
          datelor cu caracter personal sunt: bistrogarlic@gmail.com. <br />
          Aveți dreptul de formula plângere către Autoritatea Națională de
          Supraveghere a Prelucrării Datelor cu Caracter Personal. sau la
          instanțele de judecată, în cazul în care considerați că societatea
          noastra v-a încălcat vreun drept în baza prevederilor legale sau dacă
          considerați că solicitările dumneavoastră nu au fost rezolvate conform
          legiiMai multe informații găsiți la următoarea adresa:
          http://www.dataprotection.ro/?page=contact
          <br />
          <Strong>VALABILITATEA NOTIFICĂRII DE CONFIDENȚIALITATE</Strong> <br />
          Această variantă a Notificării este în vigoare din Mai 2022 și se va
          actualiza dacă apar noi cerințe legale sau modificări privind
          modalitatea de prelucrare a datelor cu caracter personal fără o
          notificare prealabilă. Sunteti responsabil să va mențineti informat cu
          privire la orice astfel de modificări care ar putea să va afecteze.{" "}
          <br />
          <br />
          <Strong> D. FACEBOOK</Strong> <br />
          <Strong> NOTIFICARE DE CONFIDENȚIALITATE</Strong> <br />
          În cele ce urmează puteți regăsi informațiile care vă explică modul în
          care colectăm în temeiul interesului legitim datele cu caracter
          personal în procesul de comunicare cu dumneavoastră prin platforma
          Facebook. <br />
          <Strong> CE INFORMAȚII COLECTĂM DESPRE DUMNEAVOASTRĂ? </Strong> <br />
          Prin platforma Facebook nu avem acces direct la datele dumneavoastră
          cu caracter personal, cu următoarele excepții: <br />
          • Vă putem contacta doar dacă, în prealabil, dvs. ne-ați contactat mai
          întâi; <br />• Dacă ne dați „Like”, profilul public de Facebook devine
          accesibil; <br />
          • În momentul în care vă postați o fotografie și vă dați „check-in” în
          locația noastră, administratorii paginii pot accesa fotografia
          dumneavoastră; <br />
          • Dacă distribuiți o postare a noastră pe profilul dumneavoastră
          personal, sau dacă distribuiți o postare personală în cronologia
          paginii locației noastre, sau recomandați Bistro Garlic unei alte
          persoane, atunci administratorii paginii sunt notificați despre
          activitatea dumneavoastră pe pagina de facebook. <br />
          <Strong> CUM FOLOSIM INFORMAȚIILE DESPRE DUMNEAVOASTRĂ? </Strong>{" "}
          <br />
          Toate informațiile mai sus menționate despre dumneavoastră sunt
          accesibile exclusiv administratorilor paginii. Nu transmitem datele
          dumneavoastră către alte entități/persoane. <br />
          <Strong> MARKETING</Strong> <br />
          Ocazional este posibil să folosim serviciul de promovare oferit de
          platforma Facebook, în condițiile stabilite de compania Facebook, pe
          care le puteți accesa din secțiunile „Termeni și condiții”, respectiv
          „Politica de confidențialitate”. <br />
          Puteți oricând renunța să mai primiți mesaje de la noi, printr-un
          proces simplu de dezabonare („unfollow”). <br />
          <Strong>
            {" "}
            ACCESUL LA INFORMAȚII, RECTIFICARE A DATELOR ȘI PLÂNGERI
          </Strong>{" "}
          <br />
          Vrem să vă asigurăm că toate drepturile dumneavoastră sunt respectate
          și că informațiile dumneavoastră cu caracter personal sunt integral
          protejate. Datele de contact ale departamentului care se ocupă de
          protecția datelor cu caracter personal sunt: bistrogarlic@gmail.com
          <br />
          Aveți dreptul de a formula plângere către Autoritatea Națională de
          Supraveghere a Prelucrării Datelor cu Caracter Personal. sau la
          instanțele de judecată, în cazul în care considerați că societatea
          noastra v-a încălcat vreun drept în baza prevederilor legale sau dacă
          considerați că solicitările dumneavoastră nu au fost rezolvate conform
          legii Mai multe informații găsiți la următoarea adresă:
          http://www.dataprotection.ro/?page=contact
          <br />
          <Strong> VALABILITATEA NOTIFICĂRII DE CONFIDENȚIALITATE</Strong>{" "}
          <br />
          Această variantă a Notificării este în vigoare din Mai 2022 și se va
          actualiza dacă apar noi cerințe legale sau modificări privind
          modalitatea de prelucrare a datelor cu caracter personal fără o
          notificare prealabilă. Sunteti responsabil să va mențineti informat cu
          privire la orice astfel de modificări care ar putea să va afecteze.{" "}
          <br />
          <br />
          <Strong> E. INSTAGRAM</Strong> <br />
          <Strong> NOTIFICARE DE CONFIDENȚIALITATE</Strong> <br />
          În cele ce urmează puteți regăsi informațiile care vă explică modul în
          care colectăm în temeiul interesului legitim datele cu caracter
          personal în procesul de comunicare cu dumneavoastră prin platforma
          INSTAGRAM. <br />
          <Strong> CE INFORMAȚII COLECTĂM DESPRE DUMNEAVOASTRĂ? </Strong> <br />
          Prin platforma INSTAGRAM avem acces direct la următoarele
          dumneavoastră date cu caracter personal doar dacă, în prealabil, dvs.
          ați început să ne urmăriți pagina noastră de Instagram sau dacă
          printr-o acțiune din partea dvs. ați dat hashtag locației sau paginii
          noastre de Instagram. <br />• Datele, informatiile și fotografiile pe
          care le distribuiți din profilul dvs. <br />
          <Strong> CUM FOLOSIM INFORMAȚIILE DESPRE DUMNEAVOASTRĂ? </Strong>{" "}
          <br />
          Toate informațiile mai sus menționate despre dumneavoastră sunt
          publice pe platforma INSTAGRAM. Nu transmitem datele dumneavoastră
          către alte entități/persoane. <br />
          <Strong> MARKETING</Strong> <br />
          Dacă sunteți unul dintre follower-ii noștri veți avea acces la
          fotografiile noastre care pot avea conținut de marketing. <br />
          Puteți oricând renunța să mai primiți mesaje de la noi, printr-un
          proces simplu de dezabonare („unfollow”). <br />
          <Strong>
            {" "}
            ACCESUL LA INFORMAȚII, RECTIFICARE A DATELOR ȘI PLÂNGERI
          </Strong>{" "}
          <br />
          Vrem să vă asigurăm că toate drepturile dumneavoastră sunt respectate
          și că informațiile dumneavoastră cu caracter personal sunt integral
          protejate. Datele de contact ale departamentului care se ocupă de
          protecția datelor cu caracter personal sunt: bistrogarlic@gmail.com
          <br />
          Aveți dreptul de a vă plânge către Autoritatea Națională de
          Supraveghere a Prelucrării Datelor cu Caracter Personal sau la
          instanțele de judecată, în cazul în care considerați că societatea
          noastra v-a încălcat vreun drept în baza prevederilor legale sau dacă
          considerați că solicitările dumneavoastră nu au fost rezolvate conform
          legii. Mai multe informații găsiți la următoarea adresă
          http://www.dataprotection.ro/?page=contact
          <br />
          <Strong> VALABILITATEA NOTIFICĂRII DE CONFIDENȚIALITATE</Strong>
          <br />
          Această variantă a Notificării este în vigoare din Mai 2022 și se va
          actualiza dacă apar noi cerințe legale sau modificări privind
          modalitatea de prelucrare a datelor cu caracter personal fără o
          notificare prealabilă. Sunteti responsabil să va mențineti informat cu
          privire la orice astfel de modificări care ar putea să va afecteze.{" "}
          <br />
          <br />
          <Strong>
            F. DREPTURILE DVS. CA ȘI PERSOANĂ VIZATĂ și DATELE DE CONTACT ALE
            OPERATORULUI
          </Strong>
          <br />
          Ca si persoană vizată, ne puteți contacta (GARLIC BISTRO) în orice
          moment și în mod gratuit cu o notificare, folosind adresa de e-mail:
          bistrogarlic@gmail.com sau la numărul de telefon 0742.491.937 sau la
          adresa str. Inocențiu Micu Klein nr. 13 Cluj-Napoca, în vederea
          exercitării drepturilor dvs. în conformitate cu GDPR. Aceste drepturi
          sunt următoarele: <br />• Dreptul de a primi informații cu privire la
          prelucrarea datelor și o copie a datelor procesate (dreptul de acces,
          articolul 15 din GDPR), <br />
          • Dreptul de a solicita rectificarea datelor inexacte sau completarea
          datelor incomplete (dreptul de acces, art. 16 din GDPR), <br />
          • Dreptul de a solicita ștergerea datelor cu caracter personal și, în
          cazul în care datele cu caracter personal au fost făcute publice,
          transmiterea informațiilor referitoare la solicitarea de ștergere
          către alți operatori (dreptul de ștergere, articolul 17 GDPR), <br />
          • Dreptul de a solicita restricționarea prelucrării datelor (dreptul
          la restricționare a procesării, articolul 18 din GDPR), <br />
          • Dreptul de a primi datele personale cu privire la persoana vizată
          într-un format structurat, utilizat în mod obișnuit și mecanolizibil
          și de a solicita transmiterea acestor date către un alt operator
          (dreptul la portabilitatea datelor, articolul 20 din GDPR), <br />
          • Dreptul de a se opune prelucrării datelor cu intenția de a înceta
          prelucrarea (dreptul la obiecție, articolul 21 din GDPR), <br />
          • Dreptul de a retrage oricând un consimțământ dat în vederea opririi
          unei prelucrări a datelor care se bazează pe consimțământul dvs.
          Retragerea nu va afecta legalitatea prelucrării pe baza
          consimțământului acordat înainte de retragere (dreptul de retragere a
          consimțământului, articolul 7 din GDPR). <br />
          • Dreptul de a depune o plângere la o autoritate de supraveghere dacă
          considerați că prelucrarea datelor este o încălcare a GDPR (dreptul de
          a depune o plângere la o autoritate de supraveghere, articolul 77 din
          GDPR). <br />
        </Subtitle>
      </Wrapper>
    </Layout>
  );
};

export default Despre;
const Strong = styled.text`
  font-weight: 600;
  opacity: 1;
`;
const Stele = styled.div`
  background-image: url("/images/backgrounds/fundal.png");
  background-size: cover;
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0%;
  z-index: -1;
`;
const Wrapper = styled.div`
  justify-content: center;
  display: flex;
  flex-flow: column;
  margin: 10px;
  margin-top: 100px;
`;

const Title = styled.h2`
  font-weight: bold;
  font-size: 24px;
  line-height: 110%;
  color: #ffffff;
  margin-bottom: 16px;
  text-align: center;
`;

const Title2 = styled.h2`
  font-weight: bold;
  font-size: 18px;
  line-height: 110%;
  color: #ffffff;
  margin-bottom: 16px;
  text-align: center;
`;

const Subtitle = styled.h2`
  font-weight: normal;
  font-size: 14px;
  line-height: 110%;
  color: #ffffff;
  opacity: 0.6;
  max-width: 800px;
  margin: auto;
`;
